<template>
    <div style="height: 100%;">
        <LoginScreen />
    </div>
</template>

<script>
import LoginScreen from '../../newArea/common/LoginScreen.vue'
export default {
    components: {
        LoginScreen,
    },
}
</script>

<style lang="scss" scoped></style>