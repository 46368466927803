<template>
    <div style="display:flex; min-height: 100%; height: 100%; flex-direction: row;" class="">
        <v-col cols="12" sm="6" md="8" class="primaryNew footerCommunityWare">
            <div style="height: 100%;" class="d-flex align-center justify-center px-5">
                <v-img class="mb-16 pr-1" max-height="70" contain :src="logoCommunityWare"></v-img>
            </div>
        </v-col>
        <v-col cols="6" md="4">
            <div style="height: 100%;" class="d-flex align-center justify-center">
                <v-form class="" ref="loginForm">
                    <v-container style="max-width: 600px;" class="newLogin px-5">
                        <v-row class="px-10">
                            <v-col cols="12" sm="12">
                                <h3 class="font-weight-medium mb-6">Bem vindo ao Community Ware</h3>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field type="email" v-model="formData.email" label="E-mail" outlined
                                    :rules="$store.state.formRules.emailRules"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" class="pb-0">
                                <!-- autocomplete="new-password" -->
                                <v-text-field outlined v-on:keydown.enter.prevent="doLogin"
                                    :rules="$store.state.formRules.required" v-model="formData.password" label="Senha"
                                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPass = !showPass"
                                    :type="showPass ? 'text' : 'password'"></v-text-field>
                                <!-- <v-text-field type="password" v-model="formData.password" label="Senha" outlined
                                    :rules="$store.state.formRules.required"></v-text-field> -->
                            </v-col>
                            <v-col class="py-0" cols="12" sm="12">
                                <v-btn class="my-2 white--text" block color="primaryNew" @click="doLogin">
                                    Entrar
                                </v-btn>
                            </v-col>
                            <v-col class="pt-2" cols="12" sm="12">
                                <a @click="toogleRecoverPasswordDilog" href="#" class="px-1">Esqueci minha senha</a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
        </v-col>
        <RecoverPassword :toogleRecoverPasswordDilog="toogleRecoverPasswordDilog"
            :recoverPasswordDilog="recoverPasswordDilog" />
    </div>
</template>

<script>
import RecoverPassword from "../../components/common/RecoverPassword.vue";
export default {
    methods: {
        toogleRecoverPasswordDilog() {
            this.recoverPasswordDilog = !this.recoverPasswordDilog;
        },
        doLogin() {
            // console.log('obj');
            if (!this.$refs.loginForm.validate()) {
                return;
            }
            var obj = {
                url: "/api/login",
                query: this.formData,
                method: "post",
            };
            // console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                let loginUrl = "";
                localStorage.setItem("userName", response.data.name);
                localStorage.setItem("userEmail", this.formData.email);
                localStorage.setItem("userId", response.data.user_id);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem(
                    "contractSignatory",
                    response.data.contract_signatory
                );
                if (response.data.type == "Admin") {
                    localStorage.setItem("userType", "adm");
                    loginUrl = "/companies";
                } else {
                    localStorage.setItem("userType", "client");
                    localStorage.setItem("partner_id", response.data.partner_id);
                    loginUrl = "/cliente/home";
                }
                this.$store.dispatch("setCookie", {
                    cname: "loginUrl",
                    cvalue: loginUrl,
                    exdays: 1,
                    flashCookie: 10000,
                });
                document.location.reload();
            });
        },
        async goToPage() {
            let url = await this.$store.dispatch("getCookie", "loginUrl");
            if (url) {
                return url;
            } else {
                return "";
            }
        },
    },
    mounted() {
        this.goToPage().then((resp) => {
            // console.log('gotp')
            if (resp) {
                this.$router.push(resp);
                if (resp == "/companies") {
                    this.$store.commit("setUserType", "adm");
                } else {
                    this.$store.commit("setUserType", "client");
                }
            } else {
                localStorage.setItem("userType", "");
                this.$store.commit("setUserType", "");
            }
        });
    },
    data() {
        return {
            recoverPasswordDilog: false,
            formData: {
                email: "",
                password: null,
            },
            showPass: false,
            logoCommunityWare: require("@/assets/newArea/logo-community-ware.png"),
        }
    },
    components: {
        RecoverPassword,
    },
}
</script>

<style>
input:-webkit-autofill {
    box-shadow: none !important;
}

.footerCommunityWare {
    background: url('../../assets/newArea/bg-footer.png') left bottom;
    background-repeat: repeat-x;
    background-size: 700px;
}
</style>