<template>
  <v-dialog light v-model="recoverPasswordDilog" persistent max-width="500px">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="headline px-2 mb-4">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0">
            Recuperação de senha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn
            @click="toogleRecoverPasswordDilog"
            class="mt-7"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar>
      </v-card-title>
      <v-card-text class="pb-0">
        <p class="mb-0">
          Preencha o campo abaixo com o seu e-mail, caso ele esteja cadastrado
          em nossa base de dados enviaremos uma nova senha para você.
        </p>
      </v-card-text>
      <v-card-text>
        <v-container>
          <v-form ref="recoverPassForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  :rules="$store.state.formRules.emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleRecoverPasswordDilog">
          Cancelar
        </v-btn>
        <v-btn color="green" text @click="sendMailToRecover"> Enviar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    recoverPasswordDilog(v) {
      if (!v) {
        this.email = "";
        this.$refs.recoverPassForm.resetValidation();
      }
    },
  },
  data() {
    return {
      valid: true,
      email: "",
    };
  },
  props: {
    toogleRecoverPasswordDilog: Function,
    recoverPasswordDilog: Boolean,
  },
  methods: {
    sendMailToRecover() {
      if (!this.$refs.recoverPassForm.validate()) {
        return;
      }
      var obj = {
        url: "/api/forgot-password",
        query: {
          email: this.email,
        },
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        this.toogleRecoverPasswordDilog();
        let opts = {
          message: "E-mail enviado com sucesso!",
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
  },
};
</script>

<style>
</style>