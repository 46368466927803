import Vue from 'vue'
import Router from 'vue-router'
// import Login from './views/LoginArea.vue'
import LoginNew from "./views/new/Login.vue"

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginNew
    },
    {
      path: '/admArea',
      name: 'admArea',
      component: () => import('./views/AdmArea.vue'),
      children: [
        {
          adm: true,
          path: '/companies',
          name: 'companies',
          text: 'Empresas',
          icon: 'apartment',
          component: () => import('./components/companies/Companies.vue')
        },
        {
          adm: true,
          path: '/partners',
          name: 'partners',
          text: 'Provedores',
          icon: 'groups',
          component: () => import('./components/partners/ListPartners.vue')
        },
        {
          adm: true,
          path: '/contratos',
          name: 'contracts',
          text: 'Contratos',
          icon: 'history_edu',
          component: () => import('./components/contractAdm/Contract.vue')
        },
        {
          adm: true,
          path: '/services',
          name: 'services',
          text: 'Serviços',
          icon: 'design_services',
          component: () => import('./components/services/Services.vue')
        },
        {
          adm: true,
          path: '/additional-packages',
          name: 'addPacks',
          text: 'Pacotes e promoções',
          icon: 'shop',
          component: () => import('./components/addPacks/AddPacks.vue')
        },
        {
          config: false,
          path: '/erpgateway',
          name: 'erpgateway',
          text: 'ERP Gateway',
          icon: 'usb',
          component: () => import('./components/erpGateway/ErpGateway.vue')
        },
        // {
        //   config: true,
        //   path: '/paymentgateway',
        //   name: 'paymentgateway',
        //   text: 'Gateway de pagameto',
        //   icon: 'credit_card',
        //   component: () => import('./components/paymentGateway/PaymentGateway.vue')
        // },
        {
          adm: true,
          path: '/contractsmanagements',
          name: 'contractsmanagements',
          text: 'Gerenciamento de contratos',
          icon: 'ballot',
          component: () => import('./components/contractsManagements/ContractsManagements.vue')
        },
        {
          adm: false,
          path: '/providers',
          name: 'providers',
          text: 'Aplicações',
          icon: 'account_box',
          component: () => import('./components/providers/Providers.vue')
        },
        {
          adm: true,
          path: '/customizeLoginErp',
          name: 'customizeLoginErp',
          text: 'Customizar Login de ERP',
          icon: 'palette',
          component: () => import('./components/CustomizeLoginErp.vue')
        },
        {
          adm: true,
          path: '/sistem-logs',
          name: 'sistemLogs',
          text: 'Logs do sistema',
          icon: 'integration_instructions',
          component: () => import('./components/logs/Logs.vue')
        },
        {
          adm: true,
          path: '/report-area',
          name: 'reportArea',
          text: 'Relatórios',
          icon: 'pie_chart',
          component: () => import('./components/reports/BilletsReport.vue')
        },
        {
          adm: true,
          path: '/billing-profile',
          name: 'billingProfile',
          text: 'Cobranças',
          icon: 'local_atm',
          component: () => import('./components/billing/BillingProfile.vue')
        },
      ]
    },
    // {
    //   path: '/clientArea',
    //   name: 'admArea',
    //   component: () => import('./views/ClientArea.vue'),
    //   children: [
    //     {
    //       path: '/meus-contratos',
    //       name: 'contractsClient',
    //       text: 'Contratos',
    //       icon: 'history_edu',
    //       component: () => import('./components/clients/contract/ContractClient.vue')
    //     },
    //     {
    //       path: '/subscricoes',
    //       name: 'subscriber',
    //       text: 'Subscrições',
    //       icon: 'history_edu',
    //       component: () => import('./components/clients/subscriber/Subscriber.vue')
    //     },
    //     {
    //       path: '/financeira',
    //       name: 'financial',
    //       text: 'Área financeira',
    //       icon: 'history_edu',
    //       component: () => import('./components/clients/financial/Financial.vue')
    //     },
    //     {
    //       path: '/boletos',
    //       name: 'billet',
    //       text: 'Gerador de boletos',
    //       icon: 'history_edu',
    //       component: () => import('./components/clients/billets/Billets.vue')
    //     },
    //   ]
    // },
    {
      path: '/cliente/',
      name: 'login',
      component: LoginNew
    },
    {
      path: '/clientArea',
      name: 'clientArea',
      component: () => import('./views/new/ClientArea.vue'),
      children: [
        {
          adm: false,
          path: '/cliente/home',
          name: 'clientHome',
          text: 'Home',
          icon: 'mdil mdil-home',
          // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
          component: () => import('./newArea/components/clientArea/Home.vue')
        },
        {
          adm: false,
          path: '/cliente/area-financeira',
          name: 'financialArea',
          text: 'Home',
          icon: 'mdil mdil-home',
          // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
          component: () => import('./newArea/components/clientArea/financial/FinancialArea.vue')
        },
        {
          adm: false,
          path: '/cliente/pagamentos',
          name: 'payments',
          text: 'Home',
          icon: 'mdil mdil-home',
          component: () => import('./newArea/components/clientArea/payments/PaymentHome.vue'),
          children: [
            {
              adm: false,
              path: '/cliente/pagamentos/',
              name: 'AdmPayments',
              text: 'Home',
              icon: 'mdil mdil-home',
              // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
              component: () => import('./newArea/components/clientArea/payments/AdmPayments.vue')
            },
            {
              adm: false,
              path: '/cliente/pagamentos/config-boletos',
              name: 'ConfigBillets',
              text: 'Home',
              icon: 'mdil mdil-home',
              // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
              component: () => import('./newArea/components/clientArea/payments/ConfigBillets.vue')
            },
            {
              adm: false,
              path: '/cliente/pagamentos/adm-boletos',
              name: 'AdmBillets',
              text: 'Home',
              icon: 'mdil mdil-home',
              // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
              component: () => import('./newArea/components/clientArea/payments/AdmBillets.vue')
            },
            {
              adm: false,
              path: '/cliente/pagamentos/config-cartoes',
              name: 'ConfigCreditCards',
              text: 'Home',
              icon: 'mdil mdil-home',
              // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
              component: () => import('./newArea/components/clientArea/payments/creditCard/ConfigCreditCards.vue')
            },
            {
              adm: false,
              path: '/cliente/pagamentos/adm-cartoes',
              name: 'AdmCreditCards',
              text: 'Home',
              icon: 'mdil mdil-home',
              // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
              component: () => import('./newArea/components/clientArea/payments/creditCard/AdmCreditCards.vue')
            },
          ]
        },
        {
          adm: false,
          path: '/cliente/meus-contratos',
          name: 'contracts',
          text: 'Contratos',
          icon: 'mdil mdil-home',
          // C:\trabalho\durand\marketplace\output\marketplace-web\src\views\new\ClientArea.vue
          component: () => import('./newArea/components/clientArea/contracts/ContractsHome.vue')
        },
      ]
    },
    {
      path: '/loginerp',
      name: 'loginerp',
      text: 'Login ERP',
      component: () => import('./components/common/LoginErp.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

