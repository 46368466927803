
//  
//  
// 
// 
// 
// 
// 
// 
// 
const state = {
    creditCardFlags: [
        {
            "name": "Visa",
            "imageUrl": "visa.png",
            "value": "VISA"
        },
        {
            "name": "American Express",
            "imageUrl": "american_express.png",
            "value": "AMEX"
        },
        {
            "name": "Aura",
            "imageUrl": "aura.png",
            "value": "AURA"
        },
        // {
        //     "name": "Avista",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-avista.png"
        // },
        // {
        //     "name": "BrasilCard",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-brasilcard.png"
        // },
        // {
        //     "name": "Cabal",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-cabal.png"
        // },
        // {
        //     "name": "CardBan",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-cardban.png"
        // },
        {
            "name": "Diners Club",
            "imageUrl": "diners_club.png",
            "value": "DINERS"
        },
        {
            "name": "Elo",
            "imageUrl": "elo.png",
            "value": "ELO"
        },
        {
            "name": "JCB",
            "imageUrl": "jcb.png",
            "value": "JCB"
        },
        {
            "name": "Hipercard",
            "imageUrl": "hipercard.png",
            "value": "HIPERCARD"
        },
        {
            "name": "MasterCard",
            "imageUrl": "mastercard.png",
            "value": "MASTERCARD"
        },
        {
            "name": "DISCOVER",
            "imageUrl": "discover.png",
            "value": "DISCOVER"
        },
        // {
        //     "name": "Pleno Card",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-plenocard.png"
        // },
        // {
        //     "name": "Santander",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-santander.png"
        // },
        // {
        //     "name": "Sicredi",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-sicredi.png"
        // },
        // {
        //     "name": "Sorocred",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-sorocred.png"
        // },
        // {
        //     "name": "ValeCard",
        //     "imageUrl": "http://test.host/assets/logos/logo-creditcard-valecard.png"
        // },

    ]
}
export default {
    namespaced: true,
    root: true,
    state
}
