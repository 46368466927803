import axios from 'axios'
import router from '../router'

axios.defaults.baseURL = (process.env.NODE_ENV === 'production') ?
    process.env.VUE_APP_API_URL :
    process.env.VUE_APP_API_URL_DEV
const myRouters = router

const actions = {
    async doRequest(state, obj) {
        //console.log('sssssssss', process.env.VUE_APP_API_URL)
        const _this = this
        // if (!actions.verifyAuth() && myRouters.history.current.path != '/login' && myRouters.history.current.path != '/recuperarsenha') {
        //     myRouters.push('/')
        // }
        let USER_TOKEN = localStorage.getItem('token')
        let AuthStr = 'Bearer '.concat(USER_TOKEN)
        if (USER_TOKEN) {
            axios.defaults.headers.Authorization = AuthStr
        }
        if (obj && !obj.noLoader) {
            this.commit('showLoader', {
                circle: true
            })
        }
        //console.log('multi')
        if (obj.multipart) {
            axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        } else {
            axios.defaults.headers.post['Content-Type'] = 'application/json';
        }
        let returnRequestData = await axios[(obj.method ? obj.method : 'get')](obj.url, obj.query)
            .then(response => {
                if (!obj.noLoader) {
                    _this.commit('hideLoader')
                }
                return response || ''
            })
            .catch(error => {
                if (obj.returnError) {
                    if (!obj.noLoader) {
                        _this.commit('hideLoader')
                    }
                    return error
                }

                if (error.response && error.response.status == 401) {
                    localStorage.clear();
                    myRouters.push('/login')
                    //return
                }

                let paramModal = {}
                paramModal.message = error.response ?
                    (error.response.data.errors ? Object.values(error.response.data.errors).join('<br>') :
                        (error.response.data.message)) : 'Contate o administrador do sistema'
                paramModal.title = 'Ocorreu erro'
                paramModal.type = 'error'
                paramModal.callback = null
                paramModal.btConfirm = {
                    name: 'Ok',
                    show: true
                }
                paramModal.btCancel = {
                    show: false
                }
                //if (!obj.noLoader) {
                _this.commit('hideLoader')
                //}
                _this.commit('confirmMessage/showConfirmMessage', paramModal)
                return
            })
        return returnRequestData
    },
    async addressByZipcode(state, zipcode) {
        const _this = this
        let url = 'https://viacep.com.br/ws/' + zipcode + '/json/'
        let address
        delete axios.defaults.headers["Authorization"];
        await axios.get(url).then(response => {
            address = response.data
        }).catch(error => {
            let paramModal = {}
            paramModal.message = error.response ? (error.response.data.errors ? Object.values(error.response.data.errors).join('<br>') : (error.response.data.message)) : 'Contate o administrador do sistema'
            paramModal.title = 'Ocorreu erro'
            paramModal.type = 'error'
            paramModal.btConfirm = {
                name: 'Ok',
                show: true
            }
            paramModal.btCancel = {
                show: false
            }
            _this.commit('confirmMessage/showConfirmMessage', paramModal)
        })
        return address
    },
    verifyAuth() {
        //console.log('verify')
        let now = new Date().getTime()
        if (localStorage.token && localStorage.expireToken > now) {
            return true
        } else {
            return false
        }
    },
    refreshToken() {
        let obj = {
            url: '/oauth/token',
            query: {
                grant_type: 'refresh_token',
                client_id: atob(localStorage.getItem('clientId')),
                client_secret: atob(localStorage.getItem('clientSecret')),
                refresh_token: atob(localStorage.getItem('refreshToken')),
                scope: ''
            },
            method: 'post',
            noLoader: true
        }
        actions.doRequest(obj).then(resp => {
            actions.saveTokenInfo(resp)
        })
    },
    saveTokenInfo(state, obj) {
        localStorage.setItem('token', btoa(obj.data.access_token))
        localStorage.setItem('expireToken', btoa(obj.data.expires_in))
        localStorage.setItem('refreshToken', btoa(obj.data.refresh_token))
        localStorage.setItem('clientId', btoa(obj.data.client_id))
        localStorage.setItem('clientSecret', btoa(obj.data.client_secret))
        return true
    }
}
export default {
    namespaced: true,
    root: true,
    actions
}