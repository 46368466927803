const state = {
    newAreapermissions: {
        updatedPermissionsOk: false,
        dtvgo: { hasProd: false },
        pagamento: { hasProd: false },
        checkpoint: { hasProd: false }
    },
    dtvGo: false,
    billets: false,
    subscriberJustView: false
}
const mutations = {
    setProductsPermissions(state, data) {
        // console.log('mmm')
        for (const item of data) {
            state.newAreapermissions[item.prefix].hasProd = item.status
            // console.log('store', state.newAreapermissions[it.prefix])
        }
    },
    updateNewAreaPermissions(state, data) {
        // console.log('updateNewAreaPermissions', data)
        let type = typeof data.val
        if (type == "string") {
            state.newAreapermissions[data.name] = data.val
        } else if (type == "object") {
            state.newAreapermissions[data.name] = { ...data.val }
        } else {
            state.newAreapermissions[data.name] = [...data.val]
        }
        state.newAreapermissions.updatedPermissionsOk = true
    },
    subscriberJustView(state, data) {
        state.subscriberJustView = data
    },
    updatePermissionsClient: (state, opts) => {
        // console.log('permission2')
        state.dtvGo = opts[0]
        state.billets = opts[1]
    },

}
export default {
    namespaced: true,
    root: true,
    state,
    mutations
}
