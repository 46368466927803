const state = {
    registrationTypes: [
        { id: 1, name: "CHEQUE" },
        { id: 2, name: "DUPLICATA MERCANTIL" },
        { id: 3, name: "DUPLICATA MTIL POR INDICACAO" },
        { id: 4, name: "DUPLICATA DE SERVICO" },
        { id: 5, name: "DUPLICATA DE SRVCP/INDICACAO" },
        { id: 6, name: "DUPLICATA RURAL" },
        { id: 7, name: "LETRA DE CAMBIO" },
        { id: 8, name: "NOTA DE CREDITO COMERCIAL" },
        { id: 9, name: "NOTA DE CREDITO A EXPORTACAO" },
        { id: 10, name: "NOTA DE CREDITO INDULTRIAL" },
        { id: 11, name: "NOTA DE CREDITO RURAL" },
        { id: 12, name: "NOTA PROMISSORIA" },
        { id: 13, name: "NOTA PROMISSORIA RURAL" },
        { id: 14, name: "TRIPLICATA MERCANTIL" },
        { id: 15, name: "TRIPLICATA DE SERVICO" },
        { id: 16, name: "NOTA DE SEGURO" },
        { id: 17, name: "RECIBO" },
        { id: 18, name: "FATURA" },
        { id: 19, name: "NOTA DE DEBITO" },
        { id: 20, name: "APOLICE DE SEGURO" },
        { id: 21, name: "MENSALIDADE ESCOLAR" },
        { id: 22, name: "PARCELA DE CONSORCIO" },
        { id: 23, name: "DIVIDA ATIVA DA UNIAO" },
        { id: 24, name: "DIVIDA ATIVA DE ESTADO" },
        { id: 25, name: "DIVIDA ATIVA DE MUNICIPIO" },
        { id: 31, name: "CARTAO DE CREDITO" },
        { id: 32, name: "BOLETO PROPOSTA" },
        { id: 33, name: "BOLETO APORTE" },
        { id: 99, name: "OUTROS" }
    ],
    tipoInscricao: [{ id: 1, name: "CPF" }, { id: 2, name: "CNPJ" }],
    aceite: [{ id: "A", name: "ACEITE" }, { id: "N", name: "NAO ACEITE" }]


}

export default {
    namespaced: true,
    root: true,
    state
}