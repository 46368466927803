import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false, themes: {
            light: {
                primaryNew: '#5a77b3',
                footerNew: "#5F61ED",
                hilightNew: "#437EE6",
                extraLightBlue: "#f2fbff"
            },
        },
    },
    icons: {
        iconfont: 'md',
    },
});
