<template>
  <v-app :class="[!viewNewArea ? '' : 'newMktPlace']">
    <div v-if="!viewNewArea" class="wrappSistem">
      <v-main :class="[clientLogged != 'adm' ? '' : 'mainLogin']">
        <router-view></router-view>
      </v-main>
      <v-footer v-if="clientLogged == 'adm'" style="border-top: 1px solid #000 !important" color="white"
        class="pt-5 px-5 pb-4">
        <v-row :class="['black--text',
          'justify-center font-weight-light',
          'caption',
        ]">
          © 2021. Todos os direitos reservados.
        </v-row>
      </v-footer>
    </div>

    <!--  -->
    <div v-if="viewNewArea" class="wrappSistem">
      <router-view></router-view>
    </div>
    <loader :loaderOn="loaderOn" :linearLoader="$store.state.loader.linearLoader"
      :circleLoader="$store.state.loader.circleLoader"></loader>
    <snackbar :timeout="$store.state.snack.timeout" :snackMessage="$store.state.snack.snackMessage"
      :typeSnack="$store.state.snack.typeSnack"></snackbar>
    <confirm-message :message="$store.state.confirmMessage.message" :title="$store.state.confirmMessage.title"
      :type="$store.state.confirmMessage.type" :btConfirm="$store.state.confirmMessage.btConfirm"
      :btCancel="$store.state.confirmMessage.btCancel" :callback="$store.state.confirmMessage.callback"
      :cancelCallback="$store.state.confirmMessage.cancelCallback"></confirm-message>
  </v-app>
</template>

<script>
const WindowH = window.innerHeight - 150;
//import Login from "./components/Login";
// import AppBar from "./components/AppBar";
//import GridFinancial from "./components/GridFinancial";
import loader from "./components/common/Loader";
import snackbar from "./components/common/Alerts";
import confirmMessage from "./components/common/Confirm-message";
export default {
  name: "App",
  watch: {
    '$route': {
      handler: function (v) {
        if (v.fullPath.indexOf('/cliente/') > -1) {
          this.viewNewArea = true
        }
        // console.log(v.fullPath.indexOf('/new/'))
      },
      deep: true,
      immediate: true
    },
    clientLogged(v) {
      // console.log('clientLogged', v)
    }
  },
  components: {
    loader,
    snackbar,
    confirmMessage,
  },
  mounted() {
    //console.log(localStorage.getItem("userType"));
    // console.log('logarea', this.clientLogged);
    this.$store.commit("setUserType", localStorage.getItem("userType"));
    // console.log('clientLogged', this.$store.state.clientLogged)
  },
  computed: {
    clientLogged: function () {
      return this.$store.state.clientLogged;
    },
    loaderOn: function () {
      return this.$store.state.loader.loaderOn;
    },
  },
  data: () => ({
    viewNewArea: false
  }),
};
</script>

<style>
.rangeInputDate label {
  font-size: 13px !important;
}

.wrappSistem {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.mainLogin {
  background-image: url(./assets/tv01-black.jpg);
  background-size: cover;
}

.btMicro {
  min-width: auto !important;
  max-width: 36px !important;
}

.btAverageSize {
  width: 26px !important;
  min-width: 26px !important;
  height: 26px !important;
}

.tooltipFilter {
  margin-left: 20px !important;
  margin-top: 10px !important;
}

.filterAbsolut {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
}

.filterAbsolut input::placeholder {
  color: white !important;
}

.filterAbsolut label {
  color: white !important;
}

.filterAbsolutAdm {
  position: absolute;
  top: 0;
  right: 150px;
  width: 300px;
}

/* .filterAbsolutAdm input::placeholder {
  color: white !important;
}
.filterAbsolutAdm label {
  color: white !important;
} */

.bordTop {
  border-top: 1px solid #ccc;
  margin: 0;
}

.bordBottom {
  border-bottom: 1px solid #ccc;
}

.cPointer {
  cursor: pointer;
}

.requiredField label::after {
  content: " *";
  font-weight: bold;
  color: red;
  font-size: 18px;
}

.specialInfo label::before {
  content: "✦ ";
  /* font-weight: bold; */
  color: rgb(59, 137, 255);
  font-size: 15px;
}

.maxw-350 {
  max-width: 350px;
}

.maxw-300 {
  max-width: 300px;
}

.minw-200 {
  min-width: 200px;
}

.newPagination .v-data-footer__select,
.newPagination .v-data-footer__icons-before,
.newPagination .v-data-footer__icons-after {
  display: none !important;
}

.newPagination .v-data-footer {
  display: block !important;
}

.newPagination .v-pagination__navigation,
.newPagination .v-pagination__item {
  height: 24px !important;
}
</style>
